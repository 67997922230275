<template>
  <v-dialog v-model="bShowExport" hide-overlay persistent width="600">
    <v-card>
      <v-card-title class="titleslight--text">
        Exportar a Excel {{isPagina ? 'pagina Actual' : 'Todos los resultados'}}
        <v-spacer></v-spacer>
      <v-icon class="ma-2" color="primary" text @click="CloseDialog()">fas fa-times</v-icon>
      </v-card-title>
      <v-card-text>
        <v-progress-linear
          active
          :indeterminate="bFinish"
          color="primary"
          class="mb-0"
          height="20"
          rounded>
          <strong>{{ sLabel }}</strong>
        </v-progress-linear>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          small
          color="primary"
          class="ma-2"
          :disabled="bGenerate"
          @click="getForm()">
          <v-icon x-small left dark>fa-file-excel</v-icon>
          Iniciar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          small
          color="primary"
          class="ma-2"
          :disabled="!bGenerate"
          @click="onExport()">
          <v-icon x-small left dark>fas fa-cloud-download-alt</v-icon>
          Descargar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import XLSX from 'xlsx'
import axiosServices from '@/services/axiosServices.js'
export default {
  name: 'ExportExcelFormDinamicos',
  props: {
    bShowExport: {
      type: Boolean,
      default: false
    },
    TypeExport: {
      type: String,
      default: 'xlsx'
    },
    nIdForm: {
      type: String,
      default: '1'
    },
    isPagina: {         // Indica SI es o NO es solo la pagina actual
      type: Boolean,
      default: true
    },
    headers: {        // Traer los Titulos de la Pagina que esta visualizando 'lo actual ' con filtros y todo
      type: Array,
      default: () => []
    },
    dataItems: {        // Traer la data info de la Pagina que esta visualizando 'lo actual ' con filtros y todo
      type: Array,
      default: () => []
    },
    filtros: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      json_meta   : [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
      sLabel  : '',
      bFinish : false,
      bGenerate: false,
      nameArchive : 'NombrearchivoCambiar',
      aHeadersForm: [],         // la estructrua de cabecera
      aItemsForm: [],           // tienen la informacion de la data como tal
      aHeadersSubForm : [],
      aItemsSubForm: [],
    }
  },
  methods: {
    async getForm() {

      // En este metodo se trae o adapat la informacion para generar el excel
      // si es pagina se toma la informacion que llega se adpata y se pide los subFormularios 
      // si es too se solicita toda la inforamcion 

      this.aHeadersForm = []    
      this.aItemsForm = []  
        this.bFinish = true
        this.sLabel =  'Generando...'
      
      if(this.isPagina){
        // se adpata la informacion para esto se recorre el registro de datos y se le 
        this.ExportPaginaActual()
      } else {
        // se solicita toda la información 
        let sParam = `idNombreForm=${this.nIdForm}&filtros=${this.filtros}`
        let aDataExport = await axiosServices.getConfiguration('/dinamicgrid_getExportAll', true, sParam)

        if (aDataExport.nStatusCode == 200) {
          this.ExportTodo(aDataExport)
        } else {
          this.$store.dispatch('notificaciones/SetMensaje', `${aDataExport.sMessage}`)
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/ShowNotificacion', true)
        }

      }
    },
    ExportTodo(aDataExport){
      // recorro los elementos que llegan y se genera un arreglo con solo los valores. Se quita acciones
      const aHeaderAux =  [] 

      let headers = aDataExport.aData.headers
      let dataItems = aDataExport.aData.InfoPrincipal

      headers.forEach( (header, index) => {
         aHeaderAux[header.value] = header.text
         this.aHeadersForm[index] = header.text
      })

      dataItems.forEach((elemento, index) => { 
        let auxObject = {}
        for (let e in aHeaderAux ) {
          auxObject[aHeaderAux[e]] = elemento[e] === undefined ? '' : elemento[e]
        }
        this.aItemsForm[index] = auxObject
      })
     
      this.aHeadersSubForm = aDataExport.aData.InfoSub.headers
      this.aItemsSubForm = aDataExport.aData.InfoSub.information

      this.bFinish = false
      this.bGenerate = true
      
    },
    ExportPaginaActual(){
      // recorro los elementos que llegan y se genera un arreglo con solo los valores. Se quita acciones
      const aHeaderAux =  [] 
      let ListIDFormRespuestas = ''

      this.headers.forEach( (header, index) => {
        if (header.text != 'ACCIONES'){
         aHeaderAux[header.value] = header.text
         // devido a que acciones esta en la posicion 0 resto 1 a los indices
         this.aHeadersForm[index - 1] = header.text
        }
      })
      
      this.dataItems.forEach((elemento, index) => { 
        let auxObject = {}
        for (let e in aHeaderAux ) {
          auxObject[aHeaderAux[e]] = elemento[e] === undefined ? '' : elemento[e]
        }
        this.aItemsForm[index] = auxObject
        ListIDFormRespuestas = elemento['idRespuesta'] + ',' + ListIDFormRespuestas
      })
      this.getSubFormularios(ListIDFormRespuestas.substring(0,ListIDFormRespuestas.length -1))
      
    },
    getSubFormularios(ListIDFormRespuestas) {
      let sParam = `idNombreForm=${this.nIdForm}&ListIDFormRespuestas=${ListIDFormRespuestas}`
      axiosServices.getConfiguration('/dinamicgrid_getSub', true, sParam).then(aDataExport => {
        if (aDataExport.nStatusCode == 200) {
          this.aHeadersSubForm = aDataExport.aData.headers
          this.aItemsSubForm = aDataExport.aData.information
          this.bFinish = false
          this.bGenerate = true
          this.sLabel = "Listo para descargar!"
        } else {
          this.$store.dispatch('notificaciones/SetMensaje', `${aDataExport.sMessage}`)
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/ShowNotificacion', true)
        }
      })
    },
    onExport () { // On Click Excel download button
      // export json to Worksheet of Excel
      // only array possible
      var SheetForm = XLSX.utils.json_to_sheet(this.aItemsForm, {header: this.aHeadersForm})
      var SheetSubForm = XLSX.utils.json_to_sheet((typeof this.aItemsSubForm == "object") ? Object.values(this.aItemsSubForm) : this.aItemsSubForm, {header: this.aHeadersSubForm})
      
      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, SheetForm, 'Formulario Principal')// sheetAName is name of Worksheet
      XLSX.utils.book_append_sheet(wb, SheetSubForm, 'Subformulario')// sheetAName is name of Worksheet
      
      // export Excel file
      XLSX.writeFile(wb, `${this.nameArchive}.${this.TypeExport}`) // name of the file
      this.CloseDialog()
    },
    CloseDialog() {
      this.bGenerate = false
      this.sLabel = ""
      this.$emit('input', false)
    }
  },
  watch: {
    bShowExport(bNewValue) {
      if (bNewValue == true) {
        this.nameArchive = 'Formdinamic'
      }
    },
  }
}
</script>