<template>
  <div class="pa-6 ma-6" fluid>
    <v-checkbox v-if="VerPregunta"
      v-model="VerPregunta"
      label="Titulos/Preguntas">
    </v-checkbox>
    <v-data-table
      :headers="formatHeader"
      :loading="cargando"
      :items="dataItems"
      :items-per-page.sync="itemsPerPagina"
      :page.sync="paginaselect"
      :search="quitSearch"
      hide-default-footer>
      <template v-slot:[`item.actions`]="{ item }">
        <component v-for="(boton,index) in BtnAcciones" :key="index"
          :is="boton.componente"
          :item="item"
          :HoverMensaje="boton.mensaje"
          @btnActionEvento="btnActionEvento">
        </component>
      </template>
      <template v-slot:top >
        <v-container grid-list-xs fluid> 
          <v-row justify-center  class="headforms" >
            <v-col md="5" sm="9" >
              <v-text-field
                v-model="quitSearch"
                label=" Busqueda rápida en la página"
                append-icon="fa-search"
                background-color="white"
                flat
                hide-details
                solo
                rounded
                clearable
                dense>
              </v-text-field>  
            </v-col>
            <v-col v-if="bActiveFilter" md="1" sm="3" class="pt-4 d-flex justify-center">
              <v-btn small color="btncolor font-weight-bold"  dark @click="filtrar">Filtrar</v-btn>
            </v-col>
            <v-col md="3" sm="3" offset-md="3" class="pt-5 d-flex justify-end">
              <v-menu
                offset-y
                :close-on-content-click="false"
                transition="slide-y-transition"
                :disabled=disableExp>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text small color="btncolor" :dark=!disableExp :disabled=disableExp> 
                    <span class="font-weight-bold subtitle-2" >Exportar</span> 
                    <v-icon small right dark>fa-chevron-right</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="exportar('xlsx', true)">
                    <v-list-item-icon>
                      <v-icon>fa-file</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Exportar página actual a Excel</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="exportar('xlsx',false)">
                    <v-list-item-icon>
                      <v-icon>fa-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Exportar todas las páginas a Excel</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>  
        </v-container>
        <v-container v-show="filtrarActive">
          <v-card style="overflow:auto">
            <v-toolbar
              height="40"
              elevation="0"
              dense>
              <v-spacer></v-spacer>
              <span class="font-weight-black mdlHeadForms">Filtros Detallados</span>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="filtradoEstate">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="font-weight-black"
                    color="btncolor"
                    @click="LimpiarFiltros()"
                    outlined
                    dark>
                    <v-icon small>fa-times-circle</v-icon>
                    Limpiar Filtros
                  </v-btn>
                </template>
                <span>Limpiar Filtros</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <FilterSecction
                :Headers="headers"
                @ejecutarFiltro="getConsultaFiltro">
              </FilterSecction>
            </v-card-text>
          </v-card>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-divider ></v-divider>
        <v-row justify-center class="pa-0 ma-0">
          <v-col md="2" sm="12" class="pt-5" >
            <v-chip
              class="ma-2"
              color="btncolor"
              text-color="black"
              outlined>
              <span class="titlesdark--text font-weight-black mr-2"  >{{totalRegistros}} &nbsp; </span>  
              <span style="font-size: .75rem;" >  Registros Totales</span>  
            </v-chip>
          </v-col>  
          <v-col md="7" sm="12" class="pt-7">
            <v-pagination
              v-model="paginaselect"
              :circle="true"
              :length="pageLength"
              :total-visible="pageTotalVisible"
              :page="paginaselect">
            </v-pagination>
          </v-col>
          <v-col md="3" sm="6" >
            <v-row>
              <v-col cols="8" class="pt-6 text-right"  style="font-size: .75rem;" >
                <p> No. Registro </p>
              </v-col>
              <v-col cols="4" class="pl-0 ">
                <v-select style="max-width: 80px;"
                  v-model="itemsPerPagina"
                  :items="itemsPagina"    
                  dense
                  solo>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>  
    </v-data-table>
    <ExportModalDinamico
      :isPagina="isPagina"
      :bShowExport="bShowEx"
      :bExport="bShowEx"
      :TypeExport="sPropsType"
      :nIdForm="sIDForm"
      :headers="headers"
      :dataItems="dataItems"
      :filtros="JSON.stringify(this.sParamsFiltro)"
      @input="setExportModal()">
    </ExportModalDinamico>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import ExportModalDinamico from '@/components/FormDinamicos/ExportExcelFormDinamicos.vue'
import FilterSecction from '@/components/Grid/Filter_Component/FilterSecction.vue'
import BtnViewDinamico  from '@/components/Grid/Btn_Component/BtnViewDinamico.vue'
export default {
  name:'GridFormDinamicos',
  components: {
    ExportModalDinamico,
    FilterSecction,
    BtnViewDinamico
  },
  data() {
    return {
      sIDForm  : this.$route.params.sIDForm,
      headers  : [],
      dataItems : [],
      cargando : false,
      bShowEx  : false,
      sPropsType  : '',
      quitSearch  : '',
      VerPregunta : false,
      totalRegistros   : 0,
      paginaselect     : 1,
      itemsPerPagina   : 10,
      itemsPagina      : [10, 20, 50],
      pageTotalVisible : 10,
      bActiveFilter : true,
      filtrarActive : false, 
      filtradoEstate : false,
      sParamsFiltro  : {}, 
      isPagina: true,
      BtnAcciones : [
        {
          componente : 'BtnViewDinamico',
          mensaje    : 'Ver reporte de la respuetas'    
        }
      ],
      disableExp: false
      
    }
  },
  mounted() {
    this.getHeaders()
    this.refrescarItems()
    this.$store.dispatch('saveIdPrevPage', this.$route.params.sIDForm)
  },
  methods: {
    refrescarItems() {
      if (this.filtradoEstate) {
        this.getConsultaFiltro()
      } else {
        this.getItems()
        this.getTotalRegistros()
      } 
    },
    getHeaders() {
      this.cargando = true
      axiosServices.getConfiguration('/dinamicgrid_headers', true, `idNombreForm=${this.sIDForm}`).then(aHeaders =>{
        this.headers = aHeaders.aData
      })
    },
    getTotalRegistros() {
      this.cargando = true
      axiosServices.getConfiguration('/dinamicgrid_totalregistros', true, `idNombreForm=${this.sIDForm}`).then(aTotalRegistro =>{
        this.totalRegistros = aTotalRegistro.aData[0]['Cantidad']
      })
    },
    getItems() {
        this.cargando = true
        let sParams = `nopagina=${this.paginaselect}` + `&noregistros=${this.itemsPerPagina}` + `&idNombreForm=${this.sIDForm}`
        axiosServices.getConfiguration('/dinamicgrid', true ,sParams).then(adataItems =>{
          this.cargando = false;
          this.dataItems = adataItems.aData
          //console.log(this.dataItems.length)
          if (this.dataItems.length == 0) {
            this.disableExp = true
          }
        })
    },
    exportar(sTypeFile, isPagina) {
      this.isPagina = isPagina;
      this.sPropsType = sTypeFile;
      this.bShowEx = true;
    }, 
    setExportModal(bNewValue) {
      this.bShowEx = bNewValue;
    },
    getConsultaFiltro(aFiltro) {
      this.cargando = true
      this.paginaselect = 1
      // console.log('aFiltro', aFiltro);
      this.sParamsFiltro = (!this.filtradoEstate) ? aFiltro : this.sParamsFiltro ;
      let sParams = 'filtros=' + JSON.stringify(this.sParamsFiltro) + `&idNombreForm=${this.sIDForm}` + `&nopagina=${this.paginaselect}` + `&noregistros=${this.itemsPerPagina}`
      axiosServices.getConfiguration('/dinamicgrid_filtrar', true, sParams).then(aFilter =>{
        this.totalRegistros = aFilter.aData['TotalRegistros'][0].Cantidad
        this.dataItems = aFilter.aData['Data']
        this.cargando = false;
        this.filtradoEstate = true
      })
    },
    filtrar() {
      this.filtrarActive = !this.filtrarActive
    },
    LimpiarFiltros() {
      this.sParamsFiltro  = "";
      this.filtradoEstate = false
      this.filtrarActive  = false
      this.refrescarItems()
    },
    btnActionEvento(oEvento) {
      // manejo de evento de los tonones de action 
      // lleva un objeto con {btnname: '' , item: ''
      switch (oEvento.btnName) {
        case 'BtnViewDinamico': {
            this.$router.replace({path : `/FormDinamicoReporte/${oEvento.item.idRespuesta}`})
          break;
        }
        default: {
          break;
        }
      }
    },
  },
  computed: {
    formatHeader() {
      // le doy formato al header añadiendo align: 'center', sortable: true OJO todo es un texto en value
      const Headers = this.headers
      
      Headers.forEach((value, Key) => {
        if (value.visible == '0') {
          Headers.splice(Key,1)
        }
        const aux_texHeader = (value.text).split('|')
        Headers[Key].text     = this.VerPregunta ? aux_texHeader[0] : aux_texHeader[1]
        Headers[Key].width    = this.VerPregunta ? value.width/2 : value.width
        Headers[Key].align    = 'center'
        Headers[Key].sortable = true
        Headers[Key].divider  = true
        Headers[Key].class    = 'titlesdark--text font-weight-bold' 
      })
      // dejo las acciones al principo por que son muy largos los formularios - si lo quiero al final cambio unshift por push
      // ojo si emplea push toca cambiar en ExportExcelFormDinamicos el index de Acciones que esta en 0
      Headers.unshift({value:'actions', text:'ACCIONES',filtro:'0',visible: '1', width:'300', tipo:'',InnerJoin:'',align:'center',divider:true, class:'titlesdark--text font-weight-bold' })
      return Headers
    },
    pageLength() {
      return Math.ceil(this.totalRegistros/this.itemsPerPagina)
    },
  },
  watch: {
    VerPregunta() {
      this.getHeaders();
    },
    paginaselect() {
      // this.getItems()
      this.nPropPageSel = this.paginaselect;
      this.refrescarItems()

    },
    itemsPerPagina() {
      this.nPropsItemPerPage = this.itemsPerPagina;
      this.paginaselect = 1;
      // this.getItems()
      this.refrescarItems()
    },
  },
}
</script>
